import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HairTransplant from '../Department/HairTransplant/HairTransplant';
import Dentistry from '../Department/Dentistry/Dentistry';
import CosmeticTreatments from '../Department/CosmeticTreatments/CosmeticTreatments';

import './Departments.css'

const Departments = () => {
    const [active, setActive] = useState('FirstCard');

    return (
        <div id='department'>
            <div className='department-top'>
                <h5>DEPARTMENTS</h5>
            </div>

            <div className='department-menu'>
                <ul>
                    <li><Link className={active === 'FirstCard' ? 'active' : ''} onClick={() => setActive('FirstCard')} to="#hair-transplant">Gallery & Procedure</Link></li>
                    <li><Link className={active === 'SecondCard' ? 'active' : ''} onClick={() => setActive('SecondCard')} to="#dentistry">Dentistry</Link></li>
                    <li><Link className={active === 'ThirdCard' ? 'active' : ''} onClick={() => setActive('ThirdCard')} to="#cosmetic-treatments">Cosmetic Treatments</Link></li>
                </ul>
            </div>

            <div>
                {active === 'FirstCard' && <HairTransplant />}
                {active === 'SecondCard' && <Dentistry />}
                {active === 'ThirdCard' && <CosmeticTreatments />}
            </div>
        </div>
    );
};

export default Departments;