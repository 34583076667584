import React, { useEffect, useState } from 'react';
import './Blogs.css';

const Blogs = () => {
    const [articles, setArticles] = useState([]);

    // Load articles from the JSON file on component mount
    useEffect(() => {
        const loadArticles = async () => {
            try {
                const response = await fetch('/articles.json'); // Fetch from the public directory
                if (!response.ok) {
                    throw new Error('Failed to fetch articles');
                }
                const data = await response.json();
                setArticles(data.articles); // Set the articles from the JSON data
            } catch (error) {
                console.error('Error loading articles:', error);
                setArticles([]); // Optionally handle the error case
            }
        };

        loadArticles();
    }, []);

    return (
        <div className='blogs-container'>
            <h2>Latest Hair Transplant News</h2>
            {articles.length > 0 ? (
                <div className='blog-cards'>
                    {articles.map((article, index) => (
                        <div key={index} className='blog-card'>
                            {article.urlToImage && <img src={article.urlToImage} alt={article.title} className='blog-image' />}
                            <h4 className='blog-title'>{article.title}</h4>
                            <p className='blog-description'>{article.description}</p>
                            <a href={article.url} target="_blank" rel="noopener noreferrer" className='read-more'>Read more</a>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No articles available at the moment.</p>
            )}
        </div>
    );
};

export default Blogs;