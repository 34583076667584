import React, { useState, useEffect } from 'react';
import './HairTransplant.css';
import { FaLeaf, FaSyringe, FaVial, FaPaintBrush, FaUserCircle, FaStar } from 'react-icons/fa';

const HairTransplant = () => {
    const allTestimonials = [
        {
            name: "ahmed k.",
            comment: "I travelled from London for my hair transplant at Goprime. The results are incredible, and the team made me feel so comfortable throughout the process.",
            rating: 5,
            date: "3 weeks ago"
        },
        {
            name: "mehmet o.",
            comment: "As someone from Germany, I was impressed by the professionalism at Goprime. My new hairline looks completely natural, and the recovery was smoother than expected.",
            rating: 5,
            date: "1 month ago"
        },
        {
            name: "yusuf s.",
            comment: "I came from France for my procedure. The staff at Goprime were excellent, and the results of my hair transplant have exceeded my expectations.",
            rating: 4,
            date: "2 months ago"
        },
        {
            name: "omar y.",
            comment: "Being from the Netherlands, I was initially hesitant about travelling for a hair transplant. But Goprime's expertise made it worth it. My hair looks great!",
            rating: 5,
            date: "6 weeks ago"
        },
        {
            name: "ibrahim l.",
            comment: "I flew in from Sweden for my hair restoration. The attention to detail at Goprime is impressive, and I'm thrilled with my new, natural-looking hair.",
            rating: 5,
            date: "2 months ago"
        },
        {
            name: "mustafa d.",
            comment: "As an Italian resident, I chose Goprime for their reputation. The hair transplant process was smooth, and the results are fantastic. Highly recommend!",
            rating: 4,
            date: "3 months ago"
        }
    ];

    const [displayedTestimonials, setDisplayedTestimonials] = useState(allTestimonials.slice(0, 3));

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayedTestimonials(prevTestimonials => {
                const currentIndex = allTestimonials.indexOf(prevTestimonials[0]);
                const nextIndex = (currentIndex + 1) % allTestimonials.length;
                return [
                    allTestimonials[nextIndex],
                    allTestimonials[(nextIndex + 1) % allTestimonials.length],
                    allTestimonials[(nextIndex + 2) % allTestimonials.length]
                ];
            });
        }, 12000); // Changed from 5000 to 12000 milliseconds (12 seconds)

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='hair-transplant-container'>
            <div className='hair-transplant-detail'>
                <h3>hair transplant department</h3>
                <p>Our state-of-the-art hair transplant department offers advanced solutions for hair restoration, helping you regain your confidence with natural-looking results.</p>
                <div className='hair-transplant-options'>
                    <div className='hair-transplant-service'>
                        <div className='service-header'>
                            <FaLeaf className='icon' />
                            <h6>fue technique</h6>
                        </div>
                        <p>Follicular Unit Extraction for minimal scarring and natural-looking results.</p>
                    </div>
                    <div className='hair-transplant-service'>
                        <div className='service-header'>
                            <FaSyringe className='icon' />
                            <h6>dhi method</h6>
                        </div>
                        <p>Direct Hair Implantation for precise placement and denser hair growth.</p>
                    </div>
                    <div className='hair-transplant-service'>
                        <div className='service-header'>
                            <FaVial className='icon' />
                            <h6>prp therapy</h6>
                        </div>
                        <p>Platelet-Rich Plasma treatment to enhance hair growth and thickness.</p>
                    </div>
                    <div className='hair-transplant-service'>
                        <div className='service-header'>
                            <FaPaintBrush className='icon' />
                            <h6>scalp micropigmentation</h6>
                        </div>
                        <p>Non-surgical solution for the appearance of fuller hair and camouflaging scars.</p>
                    </div>
                </div>
            </div>

            <section className="testimonials">
                <h2>what our patients say</h2>
                <div className="testimonial-list">
                    {displayedTestimonials.map((testimonial, index) => (
                        <div key={testimonial.name} className="testimonial-item">
                            <div className="testimonial-header">
                                <FaUserCircle className="user-icon" />
                                <div className="user-info">
                                    <span className="testimonial-name">{testimonial.name}</span>
                                    <span className="testimonial-date">{testimonial.date}</span>
                                </div>
                            </div>
                            <div className="testimonial-rating">
                                {[...Array(5)].map((_, i) => (
                                    <FaStar key={i} className={i < testimonial.rating ? "star filled" : "star"} />
                                ))}
                            </div>
                            <p className="testimonial-comment">{testimonial.comment}</p>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default HairTransplant;