import React from 'react';
import './CosmeticTreatments.css';
import { FaSyringe, FaBolt, FaFlask, FaWeightHanging } from 'react-icons/fa';

const CosmeticTreatments = () => {
    return (
        <div className='cosmetic-treatments-container'>
            <div className='cosmetic-treatments-detail'>
                <h3>Cosmetic Treatments Department</h3>
                <p>Our cosmetic treatments department offers a range of non-invasive and minimally invasive procedures to enhance your natural beauty and boost your confidence.</p>
                <div className='cosmetic-treatments-options'>
                    <div className='cosmetic-treatments-service'>
                        <div className='service-header'>
                            <FaSyringe className='icon' />
                            <h6>BOTOX & FILLERS</h6>
                        </div>
                        <p>Reduce wrinkles and add volume for a more youthful appearance.</p>
                    </div>
                    <div className='cosmetic-treatments-service'>
                        <div className='service-header'>
                            <FaBolt className='icon' />
                            <h6>LASER TREATMENTS</h6>
                        </div>
                        <p>Improve skin texture, reduce pigmentation, and remove unwanted hair.</p>
                    </div>
                    <div className='cosmetic-treatments-service'>
                        <div className='service-header'>
                            <FaFlask className='icon' />
                            <h6>CHEMICAL PEELS</h6>
                        </div>
                        <p>Rejuvenate your skin and address various skin concerns.</p>
                    </div>
                    <div className='cosmetic-treatments-service'>
                        <div className='service-header'>
                            <FaWeightHanging className='icon' />
                            <h6>BODY CONTOURING</h6>
                        </div>
                        <p>Non-surgical fat reduction and skin tightening treatments.</p>
                    </div>
                </div>
            </div>
            {/* Commented out or removed this part: */}
            {/* <div className='cosmetic-treatments-img'>
                <img src={cosmeticTreatments} alt="Cosmetic Treatment Procedure" />
            </div> */}
        </div>
    );
};

export default CosmeticTreatments;