import React, { useState } from 'react';
import './Treatments.css';

const PatientGuide = () => {
    const [faqOpen, setFaqOpen] = useState({});

    const toggleFaq = (index) => {
        setFaqOpen((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const faqs = [
        {
            question: "Is intravenous sedation safe?",
            answer: "Intrivenous sedation is generally considered safe when administered by a qualified medical professional. However, there are some risks associated with sedation, such as allergic reactions or respiratory problems."
        },
        {
            question: "Will I be unconscious during the hair transplant procedure with intravenous sedation?",
            answer: "No, you will not be unconscious during the procedure with intravenous sedation. You will be conscious and able to respond to the doctor's instructions, but you will be relaxed and may feel drowsy."
        },
        {
            question: "How long does it take for the effects of intravenous sedation to wear off?",
            answer: "The effects of intravenous sedation can vary depending on the medication used and the dosage administered. Most patients will start to feel more alert and awake within a few hours after the procedure."
        },
        {
            question: "Can I drive myself home after the hair transplant procedure with intravenous sedation?",
            answer: "No, you should not drive yourself home after the procedure with intravenous sedation. You will need someone to accompany you and drive you home as the medication can affect your reflexes and judgement."
        }
    ];

    const steps = [
        {
            title: "Arrival",
            description: "One of our airport guides will meet you at the meeting point for both Sabiha Gökçen and Istanbul Airport. Our driver will take you to the Hotel and check-in. You should rest in your hotel or sightsee Istanbul for the rest of the day.",
            date: "On Your 1st Day"
        },
        {
            title: "The Operation Day",
            description: "If you have chosen the full hair transplant package, we will pick you up from your hotel in the morning and bring you to the Goprime Hair Clinic. Your procedures begin with a blood test. Our medical doctor must approve or recommend other tests after evaluating your blood test. In the second step, the preview and payment are made. In the third step, front line approval and graft number approval are obtained in the consultation room. Then, your hair transplant operation, which will take 6 to 8 hours, begins. After the operation, we will drop you back to your hotel.",
            date: "On Your 2nd Day"
        },
        {
            title: "Postoperative Processes",
            description: "This stage includes dressing for the transplanted area and hair treatment to accelerate the healing process of the operation area.",
            date: "On Your 3rd Day"
        },
        {
            title: "Hair Washing & Departure",
            description: "On the last day, we will pick you up again from your hotel to our clinic. The first wash after hair transplantation is very important. That's why our experienced nurses do your first wash for you. Shampoo and foam spray included in the after-hair care set given to you should be used. After the washing is finished, airport transfer is made if it is included in your package.",
            date: "On Your 4th Day"
        }
    ];

    const galleryImages = Array.from({ length: 18 }, (_, index) => ({
        image: require(`../../images/${index + 1}.jpeg`) // Single image for both before and after
    }));

    return (
        <>
            <section className="gallery-section">
                <h2>Before and After Gallery</h2>
                <div className="gallery">
                    {galleryImages.map((image, index) => (
                        <div key={index} className="gallery-item">
                            <h3>Client {index + 1}</h3>
                            <div className="image-container">
                                <img src={image.image} alt={`Client ${index + 1}`} className="client-image" />
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <div className='patient-guide-container'>
                <h1>Patient Guide: Hair Transplant Procedure</h1>
                <p>Learn about the steps involved in a hair transplant procedure and how to prepare for it.</p>

                <div className="three-column-layout">
                    <div className="column"></div> {/* Left empty column */}
                    <div className="column center-column shadow-box">
                        <div className="timeline">
                            {steps.map((step, index) => (
                                <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                                    <div className="timeline-content">
                                        <h2>{step.title}</h2>
                                        <p className="timeline-date">{step.date}</p>
                                        <p>{step.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="procedure-card">
                            <div className="procedure-card-left">
                                <h2 id='procedure-steps'>Procedure Steps</h2>
                            </div>
                            <div className="procedure-card-right">
                                <section>
                                    <h2>1. Consultation with Your Doctor</h2>
                                    <p>Discuss any medical conditions, allergies, or medications you are taking with your doctor to determine if you are eligible for intravenous sedation.</p>
                                </section>

                                <section>
                                    <h2>2. Follow Fasting Instructions</h2>
                                    <p>Follow the fasting instructions provided by your doctor to avoid any complications during the procedure.</p>
                                </section>

                                <section>
                                    <h2>3. Wear Comfortable Clothing</h2>
                                    <p>Wear comfortable clothing that is easy to remove and put on as you may need to change into a hospital gown.</p>
                                </section>

                                <section>
                                    <h2>4. Avoid Caffeine and Alcohol</h2>
                                    <p>Avoid consuming caffeine or alcohol before the procedure as it may interfere with the sedative medication.</p>
                                </section>

                                <section>
                                    <h2>5. Intravenous Sedation</h2>
                                    <p>Intravenous sedation involves administering medications through an IV line to help you relax and reduce anxiety. You will be conscious but relaxed and drowsy during the procedure.</p>
                                </section>

                                <section>
                                    <h2>6. Post-Op Aftercare</h2>
                                    <p>Follow the post-op aftercare instructions provided by your doctor to ensure a smooth recovery. This includes the first washing after the hair transplant and removing scabs and crust from the scalp.</p>
                                </section>
                            </div>
                        </div>

                        <section className="faq-section">
                            <h2>FAQs</h2>
                            {faqs.map((faq, index) => (
                                <div key={index} className="faq-item">
                                    <h3 onClick={() => toggleFaq(index)} className="faq-question">
                                        {faq.question}
                                        <span className={`toggle-icon ${faqOpen[index] ? 'open' : ''}`}>&#9654;</span>
                                    </h3>
                                    {faqOpen[index] && <p className="faq-answer">{faq.answer}</p>}
                                </div>
                            ))}
                        </section>
                    </div>
                    <div className="column"></div> {/* Right empty column */}
                </div>
            </div>
        </>
    );
};

export default PatientGuide;