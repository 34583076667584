import React from 'react';
import './Dentistry.css';
import { FaTooth, FaSmile, FaTeeth, FaBrush, FaStar, FaUserCircle } from 'react-icons/fa';

const Dentistry = () => {
    const testimonials = [
        {
            name: "Sarah M.",
            comment: "I was terrified of dentists, but the team at Goprime Clinic made me feel so comfortable. My smile has never looked better!",
            rating: 5,
            date: "2 weeks ago"
        },
        {
            name: "Mike T.",
            comment: "The dental implant procedure at Goprime Clinic was smooth and painless. I can eat and smile confidently again. Highly recommend!",
            rating: 5,
            date: "1 month ago"
        },
        {
            name: "Emily R.",
            comment: "The staff at Goprime Clinic is friendly and professional. They explained every step of my treatment. Great experience overall!",
            rating: 4,
            date: "2 months ago"
        }
    ];

    return (
        <div className='dentistry-container'>
            <div className='dentistry-detail'>
                <h3>Dentistry Department</h3>
                <p>Our comprehensive dentistry department provides a wide range of services to ensure your oral health and give you a confident smile.</p>
                <div className='dentistry-options'>
                    <div className='dentistry-service'>
                        <div className='service-header'>
                            <FaTooth className='icon' />
                            <h6>GENERAL DENTISTRY</h6>
                        </div>
                        <p>Regular check-ups, cleanings, and preventive care for optimal oral health.</p>
                    </div>
                    <div className='dentistry-service'>
                        <div className='service-header'>
                            <FaSmile className='icon' />
                            <h6>COSMETIC DENTISTRY</h6>
                        </div>
                        <p>Teeth whitening, veneers, and smile makeovers for a beautiful smile.</p>
                    </div>
                    <div className='dentistry-service'>
                        <div className='service-header'>
                            <FaTeeth className='icon' />
                            <h6>ORTHODONTICS</h6>
                        </div>
                        <p>Braces and clear aligners to straighten teeth and improve bite.</p>
                    </div>
                    <div className='dentistry-service'>
                        <div className='service-header'>
                            <FaBrush className='icon' />
                            <h6>DENTAL IMPLANTS</h6>
                        </div>
                        <p>Permanent tooth replacement solutions for a natural look and feel.</p>
                    </div>
                </div>
            </div>
            
            <section className="testimonials">
                <h2>What Our Patients Say</h2>
                <div className="testimonial-list">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-item">
                            <div className="testimonial-header">
                                <FaUserCircle className="user-icon" />
                                <div className="user-info">
                                    <span className="testimonial-name">{testimonial.name}</span>
                                    <span className="testimonial-date">{testimonial.date}</span>
                                </div>
                            </div>
                            <div className="testimonial-rating">
                                {[...Array(5)].map((_, i) => (
                                    <FaStar key={i} className={i < testimonial.rating ? "star filled" : "star"} />
                                ))}
                            </div>
                            <p className="testimonial-comment">{testimonial.comment}</p>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Dentistry;