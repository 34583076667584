import React from 'react';
import "./AboutUs.css"
import about from '../../images/about.png'
import { FaLeaf, FaHeartbeat, FaUsers, FaUserMd } from 'react-icons/fa';

const AboutUs = () => {
    return (
        <div className='aboutus-container' id="about">
            <div className='about-image'>
                <img src={about} alt="About Us" />
                <div className='image-overlay'></div>
            </div>
            <div className='about-content'>
                <h2>Why choose us?</h2>
                <div className='flip-cards-container'>
                    <div className='flip-card'>
                        <div className='flip-card-inner'>
                            <div className='flip-card-front'>
                                <FaLeaf className="card-icon" />
                                <h6>Natural Results You Can Trust</h6>
                            </div>
                            <div className='flip-card-back'>
                                <p>Our expert team delivers beautiful, natural hair transplant results using the latest techniques and technology.</p>
                            </div>
                        </div>
                    </div>
                    <div className='flip-card'>
                        <div className='flip-card-inner'>
                            <div className='flip-card-front'>
                                <FaHeartbeat className="card-icon" />
                                <h6>A Care-First Approach</h6>
                            </div>
                            <div className='flip-card-back'>
                                <p>We take the time to understand your goals, listen to your concerns, and tailor a treatment plan just for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className='flip-card'>
                        <div className='flip-card-inner'>
                            <div className='flip-card-front'>
                                <FaUsers className="card-icon" />
                                <h6>Trusted by Thousands</h6>
                            </div>
                            <div className='flip-card-back'>
                                <p>Join the countless satisfied clients who have transformed their lives with us—your confidence is our success story.</p>
                            </div>
                        </div>
                    </div>
                    <div className='flip-card'>
                        <div className='flip-card-inner'>
                            <div className='flip-card-front'>
                                <FaUserMd className="card-icon" />
                                <h6>The Best Doctors</h6>
                            </div>
                            <div className='flip-card-back'>
                                <p>Our team consists of highly skilled and experienced doctors specializing in hair transplantation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;