import React from 'react';
import './Cards.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Cards = () => {
    return (
        <nav>
            <div className='contact-cards-container'>
                <div className='contact-card'>
                    <a href="https://wa.me/905366702018" target="_blank" rel="noopener noreferrer" className='contact-link'>
                        <FontAwesomeIcon icon={faWhatsapp} className='contact-card-icon' />
                        <h4>Call Us</h4>
                    </a>
                    <a href="mailto:goprimehair@gmail.com" className='contact-link'>
                        <FontAwesomeIcon icon={faEnvelope} className='contact-card-icon' />
                        <h4>Email Us</h4>
                    </a>
                    <a href="https://www.google.com/maps/place/GoPrime+Hair+Clinic" target="_blank" rel="noopener noreferrer" className='contact-link'>
                        <FontAwesomeIcon icon={faLocationDot} className='contact-card-icon' />
                        <h4>Location</h4>
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Cards;