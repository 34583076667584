import React, { useState } from 'react'; // Added useState for form handling
import emailjs from 'emailjs-com'; // Import EmailJS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Contact.css'

// Initialize EmailJS with your public key
emailjs.init('xdOocvA6IRUAclvTx'); // Replace with your actual public key

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '', // Added name field
        email: '',
        phone: '',
        message: ''
    });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.send('service_n19drvp', 'template_yogkp4q', formData)
            .then((response) => {
                console.log('Email sent successfully:', response);
                setShowSuccessMessage(true);
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
                setTimeout(() => setShowSuccessMessage(false), 5000); // Hide message after 5 seconds
            }, (error) => {
                console.error('Failed to send email:', error);
                alert('Failed to send message. Please try again.');
            });
    };

    return (
        <div className="contact-container">
            <h1 className="contact-title">Contact Us</h1>
            <div className="contact-content">
                <div className="contact-info">
                    <div className="contact-item">
                        <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                        <div>
                            <h3>Phone</h3>
                            <p>+90 536 670 2018</p>
                        </div>
                    </div>
                    <div className="contact-item">
                        <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                        <div>
                            <h3>Email</h3>
                            <p>goprimehair@gmail.com</p>
                        </div>
                    </div>
                    <div className="contact-item">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
                        <div>
                            <h3>Address</h3>
                            <p>Istanbul, Turkey</p>
                        </div>
                    </div>
                </div>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Your Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                    <textarea
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <button type="submit" className="submit-btn">Send Message</button>
                </form>
            </div>
            {showSuccessMessage && (
                <div className="success-message">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span>Message sent successfully!</span>
                </div>
            )}
            <div className="social-links">
                <a href="https://www.facebook.com/goprimehair" className="social-icon"><FontAwesomeIcon icon={faFacebook} /></a>
                <a href="https://www.instagram.com/goprimehair" className="social-icon"><FontAwesomeIcon icon={faInstagram} /></a>
            </div>
        </div>
    );
};

export default Contact;