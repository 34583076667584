import React, { useState } from 'react';
import './Package.css';
import { FaCheck } from "react-icons/fa";

const Package = () => {
    const basicFeatures = [
        "FUE Technique: Precise and minimally invasive hair restoration for natural results.",
        "Pre-Operation Checkup: Full health assessment before the procedure.",
        "Dermatologist Appointment: Personalized skin and scalp consultation with a dermatologist.",
        "Local Anesthesia: Pain-free procedure for your comfort.",
        "Shampoo & Lotion: Post-care essentials included.",
        "3 PRP Sessions: Boosts hair growth and strengthens follicles.",
        "Medication: Tailored plan to support recovery.",
        "1 Year Follow-Up: Continuous support and monitoring."
    ];

    const advancedFeatures = [
        <strong><em>Everything including Basic Hair Treatment Package</em></strong>,
        "Mesotherapy: Nutrient-rich treatment for scalp rejuvenation.",
        "Ozone Therapy: Improves circulation and hair health.",
        "6 Months Checkups: Regular checkups to track progress.",
        "Transfer & Translation: Hassle-free transportation and translation services during your visit."
    ];

    const vipFeatures = [
        <strong><em>Everything including Advanced Hair Treatment Package</em></strong>,
        "Luxury post-op care and recovery package",
        "12 months of follow-up consultations",
        "Scalp micropigmentation touch-up",
        "Airport pickup and hotel transfers",
        "5-star hotel accommodation for 5 nights",
        "Pre-operative health check and blood tests",
        "Extended PRP treatment course (3 sessions)",
        "Personalized diet and lifestyle plan",
        "24/7 personal medical concierge",
        "Sightseeing tour of Istanbul",
        "Lifetime follow-up support"
    ];

    const packages = [
        {
            name: "Essential Hair Treatment Package",
            price: "$1,700",
            features: basicFeatures,
            unique: "Perfect for early-stage hair loss"
        },
        {
            name: "Advanced Hair Treatment Package",
            price: "$2,250",
            features: advancedFeatures,
            unique: "Ideal for moderate hair loss"
        },
        {
            name: "VIP Hair Treatment Package",
            price: "$7,500",
            features: vipFeatures,
            unique: "Complete care from arrival to long-term results"
        }
    ];

    // State to manage the toggled package details
    const [activePackageIndex, setActivePackageIndex] = useState(null);

    const togglePackageDetails = (index) => {
        setActivePackageIndex(activePackageIndex === index ? null : index);
    };

    return (
        <div className='package-container' id='package'>
            <div className='package'>
                <h1>Hair Transplant Packages</h1>
                <p>Choose the perfect hair restoration solution for you</p>
            </div>

            <div className='package-home-card'>
                {packages.map((pkg, index) => (
                    <div key={index} className='package-card'>
                        <h2 onClick={() => togglePackageDetails(index)} className="package-name">
                            {pkg.name} - {pkg.price}
                        </h2>
                        {/* Show details only on mobile */}
                        <div className={`package-details ${activePackageIndex === index ? 'active' : ''}`}>
                            <ul>
                                {pkg.features.map((feature, idx) => (
                                    <li key={idx}>
                                        <FaCheck className="icon-check" /> {feature}
                                    </li>
                                ))}
                            </ul>
                            <div className="unique-feature">
                                <strong>Unique Benefit:</strong> {pkg.unique}
                            </div>
                            <div className="button-container">
                                <button className="select-package">Choose Plan</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Package;