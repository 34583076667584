import React from 'react';
import logo from '../../images/logo.png'
import footerlogo from '../../images/footerlogo.png'
import './Footer.css'
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='footer-content'>
                <div className='footer-section'>
                    <h6>Contact with us</h6>
                    <ul className='footer-contact-class'>
                        <li><FaPhoneAlt className="contact-icon" /> <span>+905366702018</span></li>
                        <li><FaMapMarkerAlt className="contact-icon" /> <span>Merkez, Çukurçeşme Caddesi No:53 , Gaziosmanpaşa/İstanbul </span></li>
                        <li><FaEnvelope className="contact-icon" /> <span>goprimehair@gmail.com</span></li>
                    </ul>
                </div>

                <div className='footer-section'>
                    <h6>Useful links</h6>
                    <ul className='footer-links'>
                        <li><Link as to="home">Home</Link></li>
                        <li><HashLink smooth to="/home#about">About</HashLink></li>
                        <li><HashLink smooth to="/home#department">Departments</HashLink></li>
                        <li><Link to="blogs">Blogs</Link></li>
                    </ul>
                </div>

                <div className='footer-section footer-socialMedia'>
                    <h6>News Letter</h6>
                    <div>
                        <input type="text" className='footer-input' placeholder='Enter your email' />
                        <button className='footer-submit-btn'>Submit</button>
                        <ul className='footer-social-media'>
                            <li><a href="https://www.facebook.com/goprimehair"><FaFacebookF /></a></li>
                            <li><a href="https://www.instagram.com/goprimehair"><FaInstagram /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='footer-legal'>
                <p>&copy; {new Date().getFullYear()} GoPrime Hair. All rights reserved.</p>
            </div>
        </div>
    );
};

export default Footer;