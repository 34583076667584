import React, { useState, useEffect } from 'react';
import './Home.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import Cards from '../Shared/Cards/Cards';
import Services from '../Shared/Services/Services';
import Clients from '../Clients/Clients';
import Departments from '../Departments/Departments';
import Package from '../Package/Package';
import AboutUs from '../About-Us/AboutUs';

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        description: '',
        date: ''
    });
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://localhost:3000/appointments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                alert('Appointment created and email sent!');
                setShowModal(false);
            } else {
                const errorText = await response.text();
                alert('Error: ' + errorText);
            }
        } catch (error) {
            alert('Error: ' + error.message);
        }
    };

    const handleWhatsAppClick = () => {
        const message = encodeURIComponent("Hello, I would like to make an appointment.");
        window.open(`https://wa.me/${+905366702018}?text=${message}`, '_blank');
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Swiper
                pagination={{
                    type: "progressbar",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper home"
            >
                <SwiperSlide className='home-nav-slide-two'>
                    <p className='home-nav-slide-two-title'> GOPrime Hair Transplant</p>
                    <div className='home-nav-slide-two-text'>
                        <h1>The Best Hair Transplant In Turkey</h1>
                        <p>Experience the highest standards of hair transplant procedures in the heart of Turkey, performed by top surgeons 
                        with a commitment to excellence. Regain your hair and confidence with natural, lasting results—all while enjoying Turkey's vibrant culture.</p>
                        <button className='second-buton' onClick={handleWhatsAppClick}>Make Appointment</button>
                    </div>
                </SwiperSlide>
            </Swiper>
            <Cards />
            <AboutUs />
            <Services />
            <Departments />
            <Package />
            <Clients />

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                        <h2>Book an Appointment</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                Name:
                                <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
                            </label>
                            <label>
                                Email:
                                <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
                            </label>
                            <label>
                                Description:
                                <textarea name="description" value={formData.description} onChange={handleInputChange} required></textarea>
                            </label>
                            <label>
                                Date:
                                <input type="datetime-local" name="date" value={formData.date} onChange={handleInputChange} required />
                            </label>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            )}

            {showScrollToTop && (
                <button className="scroll-to-top" onClick={handleScrollToTop}>
                    ↑
                </button>
            )}
        </>
    );
};

export default Home;