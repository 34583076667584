import React from 'react';
import './NotFound.css'

const NotFound = () => {
    return (
        <div className='not-found'>
            <h1>404-Not Found</h1>
            <h2>This url is not correct</h2>
        </div>
    );
};

export default NotFound;