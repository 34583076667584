/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './Clients.css';

const Clients = () => {
    return (
        <div className='clients-top-heading' id='location'>
            <h2>Our Location</h2>
            <div className='clients-container'>
                <div className='map-container'>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.8116522339287!2d28.905708715441808!3d41.05911667929642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab109c4c0ce09%3A0x54b72058ec9723c8!2sGoPrime%20Hair%20Clinic!5e0!3m2!1sen!2sus!4v1648646234567!5m2!1sen!2sus"
                        width="100%"
                        height="400"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Clients;